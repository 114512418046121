import React from 'react';
import { TextField } from '@plug/ui';
import { useTranslation } from 'react-i18next';

const IntegrationFormNuvemShop = () => {
  const { t } = useTranslation();

  return (
    <>
      <TextField
        label={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.nuvem-shop.clientId.label',
        )}
        name="clientId"
        placeholder={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.nuvem-shop.clientId.placeholder',
        )}
        width="100%"
      />
      <TextField
        label={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.nuvem-shop.clientSecret.label',
        )}
        name="clientSecret"
        placeholder={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.nuvem-shop.clientSecret.placeholder',
        )}
        width="100%"
      />
      <TextField
        label={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.nuvem-shop.code.label',
        )}
        name="code"
        placeholder={t(
          'hubly-dashboard.settings.admin.accordion.items.integration.body.form.nuvem-shop.code.placeholder',
        )}
        width="100%"
      />
    </>
  );
};

export default IntegrationFormNuvemShop;
