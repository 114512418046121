import React, { useEffect, useState } from 'react';
import { Icon, Typography } from '@gohubly/design-system';
import HeaderActionDropdownBase from '../header-action-dropdown-base';
import {
  HeaderActionDropdownBaseHr,
  HeaderActionDropdownBaseItem,
  HeaderActionDropdownBaseActionItem,
} from '../header-action-dropdown-base/style';
import HeaderActionProfileConfirmLogout from './header-action-profile-confirm-logout';
import { useHistory } from 'react-router-dom';
import getCurrentOrganization from '@plug/helpers/get-current-organization';
import { UserStore } from '@plug/redux/store';
import { useTranslation } from 'react-i18next';
import HeaderActionsAccountInfo from '../header-actions-account-info';
import { Box, Flex } from 'rebass';
import { ENVIRONMENT } from '@plug/config/env';
import {
  EFIZI_HOST_NAME,
  ESPACO_SMART_HOST_NAME,
  HUNTER_FUN_HOST_NAME,
  IROBOT_HOST_NAME,
  KINGSPAN_HOST_NAME,
  LINDA_CASA_HOST_NAME,
  MADESA_HOST_NAME,
  MARY_KAY_WHITELABEL,
  MARY_MARIA_MAKEUP_HOST_NAME,
  NUTRIBULLET_HOST_NAME,
  OLIST_HOST_NAME,
} from '@plug/config/consts';
import useIsOrganizationAdmin from '@plug/hooks/use-is-organization-admin';
import { useAuthContext } from '@plug/contexts/auth-context';
import Environment from '@plug/ui2/environment';

const HeaderActionProfileDropdown = ({
  handleCloseDropdown,
  showLogoutConfirmation,
  handleShowLogoutConfirmation,
}: HeaderActionProfileDropdownI): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentOrganization: organizationInfo } = useAuthContext();
  const user = UserStore.useState(s => s);
  const isOrganizationAdmin = useIsOrganizationAdmin();
  const { currentOrganization } = useAuthContext();
  const isAdmin = currentOrganization?.organizationRole[0].name === 'ADMIN';
  const [logoutConfirmation, setLogoutConfirmation] = useState(
    showLogoutConfirmation !== undefined ? showLogoutConfirmation : false,
  );

  const handleGoToLogoutConfirmation = (): void =>
    showLogoutConfirmation !== undefined
      ? handleShowLogoutConfirmation(true)
      : setLogoutConfirmation(true);
  const handleCancelLogoutConfirmation = (): void =>
    showLogoutConfirmation !== undefined
      ? handleShowLogoutConfirmation(false)
      : setLogoutConfirmation(false);

  const handleProfileDropdownClose = (event?: React.MouseEvent): void => {
    handleCloseDropdown(event);
    handleCancelLogoutConfirmation();
    setLogoutConfirmation(false);
  };

  const blockHelpDesk = [
    ...MARY_MARIA_MAKEUP_HOST_NAME,
    ...LINDA_CASA_HOST_NAME,
    ...MADESA_HOST_NAME,
    ...EFIZI_HOST_NAME,
    ...KINGSPAN_HOST_NAME,
    ...OLIST_HOST_NAME,
    ...ESPACO_SMART_HOST_NAME,
    ...MARY_KAY_WHITELABEL,
    ...NUTRIBULLET_HOST_NAME,
    ...IROBOT_HOST_NAME,
    ...HUNTER_FUN_HOST_NAME,
  ].includes(window.location.hostname);

  const blockedSubscriptionManagement = ![
    ...MARY_KAY_WHITELABEL,
    ...NUTRIBULLET_HOST_NAME,
    ...IROBOT_HOST_NAME,
    ...HUNTER_FUN_HOST_NAME,
  ].includes(window.location.hostname);

  if (logoutConfirmation && showLogoutConfirmation === undefined) {
    return (
      <HeaderActionProfileConfirmLogout
        handleBackToMainDropdown={handleCancelLogoutConfirmation}
        handleCloseDropdown={handleProfileDropdownClose}
      />
    );
  }

  if (showLogoutConfirmation !== undefined && showLogoutConfirmation !== false) {
    return (
      <HeaderActionProfileConfirmLogout
        handleBackToMainDropdown={handleCancelLogoutConfirmation}
        handleCloseDropdown={handleProfileDropdownClose}
      />
    );
  }

  const handleGoToSubscription = (event?: React.MouseEvent): void => {
    history.push(`/org/${organizationInfo.slug}/subscription`);
    handleProfileDropdownClose(event);
  };

  const handleGoToChangePassword = (event?: React.MouseEvent): void => {
    history.push(`/org/${organizationInfo.slug}/affiliate/account/password`);
    handleProfileDropdownClose(event);
  };

  const help = (): void => {
    if (isAdmin) {
      window.open('https://support.help.monitfy.com/servicedesk/customer/portals', '_blank');
    } else {
      window.open('https://monitfy.atlassian.net/wiki/spaces/HD/overview', '_blank');
    }
  };

  return (
    <HeaderActionDropdownBase>
      <HeaderActionDropdownBaseActionItem>
        <HeaderActionsAccountInfo handleCloseDropdown={handleProfileDropdownClose} />
      </HeaderActionDropdownBaseActionItem>

      {/* <HeaderActionDropdownBaseItem>
        <HeaderActionDropdownBaseHr />
      </HeaderActionDropdownBaseItem> */}

      {organizationInfo && (
        <>
          <HeaderActionDropdownBaseActionItem>
            <Typography color="neutralLowDark" size="XXS" lineHeight="LG" fontWeight={600}>
              {organizationInfo.name}
            </Typography>
          </HeaderActionDropdownBaseActionItem>

          {blockedSubscriptionManagement && isOrganizationAdmin && (
            <HeaderActionDropdownBaseActionItem onClick={handleGoToSubscription}>
              <Typography color="neutralLow" size="XXS" lineHeight="LG" fontWeight={500}>
                Gerenciar assinatura
              </Typography>
            </HeaderActionDropdownBaseActionItem>
          )}
        </>
      )}

      {/* <HeaderActionDropdownBaseActionItem
        onClick={(): void => history.push(`/org/${organizationInfo.slug}/subscription`)}
      >
        <Typography color="neutralLow" size="XXS" fontWeight={500}>
          Trocar Organização
        </Typography>
      </HeaderActionDropdownBaseActionItem> */}

      <HeaderActionDropdownBaseItem>
        <HeaderActionDropdownBaseHr />
      </HeaderActionDropdownBaseItem>

      <Environment allow="staging">
        <HeaderActionDropdownBaseActionItem onClick={handleGoToChangePassword}>
          <Flex style={{ gap: '8px' }} alignItems="center">
            <Icon iconId="lock" stroke="neutralLowMedium" fill="transparent" size="SM" />

            <Typography color="neutralLow" size="XXS" lineHeight="LG" fontWeight={500}>
              {t('hubly-dashboard.header.password')}
            </Typography>
          </Flex>
        </HeaderActionDropdownBaseActionItem>
      </Environment>

      {!blockHelpDesk && (
        <HeaderActionDropdownBaseActionItem onClick={help}>
          <Flex style={{ gap: '8px' }} alignItems="center">
            <Icon iconId="helpCircle" stroke="neutralLowMedium" fill="transparent" size="SM" />

            <Typography color="neutralLow" size="XXS" lineHeight="LG" fontWeight={500}>
              {t('hubly-dashboard.header.help')}
            </Typography>
          </Flex>
        </HeaderActionDropdownBaseActionItem>
      )}

      <HeaderActionDropdownBaseActionItem onClick={handleGoToLogoutConfirmation}>
        <Flex style={{ gap: '8px' }} alignItems="center">
          <Box style={{ transform: 'rotate(180deg)' }}>
            <Icon iconId="login" stroke="neutralLowMedium" fill="transparent" size="SM" />
          </Box>

          <Typography color="neutralLow" size="XXS" lineHeight="LG" fontWeight={500}>
            {t('hubly-dashboard.header.user-dropdown.logout')}
          </Typography>
        </Flex>
      </HeaderActionDropdownBaseActionItem>
    </HeaderActionDropdownBase>
  );
};

export default HeaderActionProfileDropdown;
