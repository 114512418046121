export const DEFAULT_LANGUAGE = process.env.REACT_APP_DEFAULT_LANGUAGE || 'pt-BR';

export const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT || 'https://api-staging.monitfy.com';
// export const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT || 'https://api.plugone.io';
// export const GRAPHQL_ENDPOINT =  process.env.REACT_APP_GRAPHQL_ENDPOINT || 'http://localhost:80/graphql';

export const LOGGER_API_KEY = process.env.REACT_APP_LOGGER_API_KEY || '';

export const REST_ENDPOINT = process.env.REST_ENDPOINT || 'http://localhost:80/graphql';

export const IUGU_ACCOUNT_ID =
  process.env.REACT_APP_IUGU_ACCOUNT_ID ?? '56B09493D25D4ADD862D02CEC749371C';
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL || '/';
export const PUBLIC_DOMAIN = process.env.REACT_APP_PUBLIC_DOMAIN || 'localhost:3000';

export const BUCKET_URL = process.env.REACT_APP_BUCKET_URL;
export const BUCKET_AFFILIATE_STORE_PIXEL_PATH =
  process.env.REACT_APP_BUCKET_AFFILIATE_STORE_PIXEL_PATH;
export const BUCKET_INSIDE_SALES_STORE_PIXEL_PATH =
  process.env.REACT_APP_BUCKET_AFFILIATE_INSIDE_SALES_PIXEL_PATH;
export const NODE_ENV = process.env.NODE_ENV;

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT ?? 'staging';

export const FACEBOOK_MESSENGER_APP_ID = process.env.REACT_APP_FACEBOOK_MESSENGER_APP_ID ?? '';

export const CUSTOMER_MODULE_ENDPOINT =
  process.env.REACT_APP_CUSTOMER_MODULE_ENDPOINT ?? 'https://customer-staging.monitfy.com';

export const CATALOG_MODULE_ENDPOINT =
  process.env.REACT_APP_CATALOG_MODULE_ENDPOINT ?? 'https://catalog-staging.monitfy.com';

export const CART_MODULE_ENDPOINT =
  process.env.REACT_APP_CART_MODULE_ENDPOINT ?? 'https://cart-staging.monitfy.com';

export const CHECKOUT_FRONT_URL =
  process.env.REACT_APP_CHECKOUT_FRONT_URL ?? 'http://localhost:3001';

export const MULTILEVEL_APP_URL =
  process.env.REACT_APP_MULTILEVEL_APP_URL ?? 'https://multilevel-staging.monitfy.com';

export const AUTH_URL = process.env.REACT_APP_AUTH_URL ?? 'https://auth-staging.monitfy.com';

export const ORDERS_URL = process.env.REACT_APP_ORDERS_URL ?? 'https://orders-staging.monitfy.com';

export const REPORTS_URL =
  process.env.REACT_APP_REPORTS_URL ?? 'https://reports-staging.monitfy.com';

export const FIREBASE_API_KEY =
  process.env.REACT_APP_FIREBASE_API_KEY ?? 'AIzaSyBnULzPcyZhF7qdlcDpjS6rHR1DNkVNSVM';
export const FIREBASE_AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_API_KEY ?? 'gohubly-dashboard-staging.firebaseapp.com';
export const FIREBASE_PROJECT_ID =
  process.env.REACT_APP_FIREBASE_PROJECT_ID ?? 'gohubly-dashboard-staging';
export const FIREBASE_DATABASE_URL =
  process.env.REACT_APP_FIREBASE_DATABASE_URL ??
  'https://gohubly-dashboard-staging-default-rtdb.firebaseio.com';
